body {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #0f2f45;
  border: solid 1px transparent;
  border-radius: 100vh;
}

.wallet-popover::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px transparent !important;
  border: solid 1px transparent;
  border-radius: 100vh;
}

.wallet-popover::-webkit-scrollbar {
  width: 0px;
}

.download-excel-button {
  border: none;
  background: #10ad4c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  padding: 6px 18px 6px 18px;
  border-radius: 3px;
  cursor: pointer;
}
.download-excel-button:disabled {
  opacity: 0.5;
}

.download-excel-button img {
  width: 25px;
  margin-left: 5px;
}

.excel-export-container {
  margin: 25px 0px;
}
.ant-table-cell > div {
  min-height: 32px;
}

.ant-pagination li > button > span,
.ant-pagination li > a > div > span {
  color: white !important;
}
.ant-pagination li > button > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination li > a > div > span {
  vertical-align: 0;
}
.ant-pagination li > a {
  color: white !important;
}

.ant-pagination li.ant-pagination-item-active > a {
  color: #4096ff !important;
}

.ant-table-content .ant-table-cell > span {
  height: 40px;
  padding-top: 6px;
}

.ant-upload-list.ant-upload-list-text {
  margin-top: 12px;
}

.ant-upload-list-item,
.ant-upload-list-item .ant-upload-icon > span svg {
  color: #ffffff;
}
.ant-upload-list-item .ant-upload-icon,
.ant-upload-list-item .ant-upload-list-item-actions {
  display: flex;
  align-items: center;
}
.ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action {
  opacity: 1 !important;
}

.ant-upload-list-item .ant-upload-list-item-actions span.anticon-delete {
  color: white !important;
  display: flex;
  align-items: center;
}

.Toastify .Toastify__toast {
  background-color: #081824;
  border-radius: 12px;
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.3);
}

.Toastify .Toastify__toast-body {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.Toastify .Toastify__toast--default {
  color: #ecf4fb;
}

.Toastify .Toastify__toast--warning {
  color: #ffe318;
}

.Toastify .Toastify__toast--success {
  color: #52ae6c;
}

.Toastify .toast-success-buy-ticket {
  margin-top: 100px;
  color: #5b5b7b;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  background-color: #ffffff;
}

.Toastify .Toastify__toast--info {
  color: #ecf4fb;
}

.Toastify .Toastify__toast--error {
  color: #c62828;
}

.Toastify .Toastify__close-button {
  color: white;
  align-self: center;
}
.Toastify .Toastify__close-button svg {
  height: 18px;
  width: 18px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
